<template>
    <div class="add-new-empoyee">
        <title-bar
            v-if="employee"
            submitBtn
            :submitBtnTitle="$t('messages.employeeUpdate')"
            :title="employee.givenName + ' ' + employee.familyName"
            @submitPressed="submitPressed"
        />
        <b-row>
            <b-col sm="3">
                <b-card>
                    <b-list-group id="user-list">
                        <b-list-group-item
                            v-for="(item,index) in employeeList"
                            :key="index"
                            :href="$helper.getEditUrl('employees', item.id)"
                            :id="item.active ? 'active-item' : ''"
                            :active="item.active"
                            :scroll="item.active ? scrollToName(index) : ''"
                            class="d-flex justify-content-between align-items-center"
                        >
                            {{ item.givenName }} {{ item.familyName }}
                            <div>
                                <b-badge v-if="item.department" variant="light">
                                    {{ item.department.name }}
                                </b-badge>
                                <b-badge :variant="item.enabled ? 'success' : 'danger'">
                                    {{ item.enabled ? 'active' : 'inactive' }}
                                </b-badge>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-col>
            <b-col sm="9">
                <b-card no-body>
                    <b-tabs card content-class="mt-3">
                        <b-tab :title="$t('forms.info')" active>
                            <employee-form
                                v-if="employee"
                                :employeeObject="employee"
                                :action="action"
                                @clearAction="action = null"
                            />
                        </b-tab>
                        <b-tab :title="$t('forms.files')">
                            <files-form :resource="$Users" :resource-id="$route.params.id"
                                        :file-tags="[ 'contract','passportScans','idScans','certificateScans','otherFiles']"/>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import EmployeeForm from "./components/EmployeeForm.vue";
import FilesForm from "@/components/Files/FilesForm.vue";

export default {
    components: {
        TitleBar,
        EmployeeForm,
        FilesForm
    },
    data() {
        return {
            employee: null,
            action: null,
            employeeList: null
        };
    },
    created() {
        this.load();
        this.loadList();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Users.getResource({id},"employees").then((response) => {
                this.employee = response.data;
                this.employee.startOn = new Date(response.data.startOn)
                this.employee.endOn = new Date(response.data.endOn)
                this.employee.birthdate = new Date(response.data.birthdate)
            });
        },
        loadList() {
            const params = {
                role: "ROLE_EMPLOYEE",
                pagination: false,
                'order[enabled]': "desc",
                'order[familyName]': "asc"
            };
            this.$Users.getCollection({params}, "user_list_dp").then((response) => {
                this.employeeList = response.data["hydra:member"];
                this.employeeList.forEach(element => {
                    if (element.id === this.$route.params.id) {
                        element.active = true
                    }
                });
            });
        },
        scrollToName(index) {
            document.getElementById("user-list").scrollTop = index * 40
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
